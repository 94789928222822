<template>
  <div class="home flex justify-center">
    <div class="bg-white auth-form self-center">
      <div class="flex justify-center my-8">
        <img src="../assets/chuuselogo.png" class="w-40 h-full" alt="chuuse logo" />
      </div>
      <div class="my-5">
        <form
          @submit.prevent="loginUser"
          class="flex flex-col w-full"
          action=""
        >
          <div class="form-group pb-6">
            <label class="text-gray-800 font-semibold text-xl" for="email"
              >Email address:</label
            >
            <input
              v-model="login.email"
              class="p-2 mt-2"
              type="email"
              name="email"
              required
              placeholder="Enter your email address"
            />
          </div>
          <div class="form-group pb-8">
            <label for="Password" class="text-gray-800 font-semibold text-xl"
              >Password</label
            >
            <input
              class="p-2 mt-2"
              required
              v-model="login.password"
              type="password"
              name="password"
              placeholder="Enter your password"
            />
          </div>
          <button
            :disabled="signIn"
            type="submit"
            class="
              bg-primary
              rounded-md
              text-white
              font-semibold
              text-lg
              w-full
              py-4
              flex
              justify-center
            "
          >
            Login &nbsp; <loader :loading="loading" class="self-center" />
          </button>
        </form>
      </div>
      
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import toastr from "toastr";
import "toastr/build/toastr.css";
import { isLoggedIn } from "../util/user";
import loader from "@/components/loading.vue";
export default {
  name: "Home",
  components: {
    loader,
  },
  data() {
    return {
      login: {
        email: "",
        password: "",
      },
      signIn: false,
    };
  },
  computed: {
    loading() {
      return this.$store.getters.gettingUsers;
    },
    errMessage() {
      return this.$store.getters.getErrMessage;
    },
  },
  mounted() {
    const x = isLoggedIn();
    if (x) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    async loginUser() {
      this.signIn = true;
      const payload = {
        email: this.login.email,
        password: this.login.password,
      };
      await this.$store.dispatch("login", payload);
      if (this.errMessage) {
        this.signIn = false;
        toastr.error(`${this.errMessage}`, "Login Error");
        this.login.email = "";
        this.login.password = "";
      } else {
        this.signIn = false;
        this.$router.push("/dashboard");
      }
    },
  },
};
</script>
<style scoped>
.home {
  background-image: url("../assets/login-bg.png");
  background-size: cover;
  height: 100vh;
  width: 100vw;
}
.auth-form {
  width: 500px;
  height: 420px;
  padding: 0 20px;
  border-radius: 6px;
}
input {
  background: none;
  border: 1px solid gray;
  display: block;
  margin-top: 1rem;
  width: 100%;
  border-radius: 6px;
}
input:focus {
  outline: none;
}
input::placeholder {
  color: white;
}
@media screen and (max-width: 768px) {
  .auth-form {
    width: 85%;
    height: 70%;
  }
}
#toast-container > div {
  color: #727070;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  z-index: 999999;
  /* &:hover {
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.07);
  } */
}
.toast-error {
  background-color: #fddddd;
}
#toast-container > .toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHLSURBVHgBpVRdUsJADE52/BnfOEI9gFjwwfqknkA9gXADbwCcgOEEcAPxBMoTjA+wo/hMvUHfHEfcmLRTKW1A0W+mw/Jl8202mwRBwcQ/KxnzVjMAp0joE4InPK8toQsJ8K48HvU0X8wTT0cnl0iuy6YSrAE7hg6glRc2S2LVkzYS3f4kJiAAj0W7z9WgoQq+sAGBbmBzNKfV4zYsIpfIgpqcBv8AObwq22E/FpxWg5lcobiLIsDc9TUuiS2au919I9FpYpLwOWGFCMKFFoTC8bKlxMiV8V4zCHihneYc9Cp2FH4SnIuQfLIWbs42FogKXuTODKDztNO2DdxP/MBLRVMx4cSmVYJBPER+doIVkFr7cImQ/E/F1HynorAGjn7HLUcJi6Rnkc2ZRJa9fvahck7WcF/aogWjfM7yOdUehYN/NQ7cQDmqtGXgOpsz+VLRHbap7UnYx4nvs/PuTO9fiSLPa1ySovJktG8q1kbkqA4q1I7QBwclxR6/ctk+9nljB/6IeIzZZIwtzUOuySb/NGAjYOdgPPyeUsUB6wc1ZhuIq4s3Bg8JIlOXCbMkv2q/CKOhC86Nx9PFjzdLTyNZ7vOBgz3u9YdC6XwBwf7zAmlxT4EAAAAASUVORK5CYII=") !important;
}
.toast-success {
  background-color: #d9ffec;
}
#toast-container > .toast-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGXSURBVHgBpVTNUcJAFP52QcdjOjBWYIYGDBUIenJETajCm1CB2IAEhavgTW9YgemA0AF3xqzfJowQCCSRbyY7m/fyvrx/gTR0HQMHcCDkGaAsKJgLjQ8hAvyE77j1vDRTsSF5dWoUd6kxsBsBQtVeJy4lPuk3H0nU4XOEbBj0tobLCvD2/bVJOGg+MLx7FIeNi4pB0k/9Eof84jiQDHMfKFXHjTeKCfvuhKeJQlBj+mOvCGaYqxMZeVecrIeGV2VR3BVh1BmSrXGOQojInOhaksdrSpuEykyxCljB0U4yXUSlWkm9OJU8rTUrnYsqrp/rvPfyk0UwZYrQwKG8i26NrhOT5iKLfcSAFVYpRRGiRS/bCVkGGeFLhMJPVWnDqNlzk2mjaRki5NhwhLaTLu9ZCMHG1pulLCY5lkEWAuacje16M/7exb7Qm4eIq8wZZEhP+C/Uco0tt83Q/0DdEqyujSIQdKTh/W2p5D4c+mPUrClJdbPvzqmCTtUVyToJ/q0GemmUOOcqGk1r8XXAw4diZ8zhxflP4heefaBbVuhCHwAAAABJRU5ErkJggg==") !important;
}
</style>