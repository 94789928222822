<template>
  <div class="">
    <div class="md:flex md:justify-between mt-5">
      <h1 class="text-2xl font-semibold mb-4">All Talents</h1>
      <!-- Search and Profile -->
      <div class="md:flex gap-x-4 md:justify-end items-center md:mt-0">
        <div class="bg-gray-light text-gray-medDark flex rounded-xl lg:w-64">
          <i class="uil uil-search text-lg px-2 self-center"></i>
          <input
            type="search"
            class="bg-gray-light outline-none py-3"
            name=""
            v-model="searchInput"
            placeholder="Search"
            @keydown="search"
          />
        </div>
        <div class="w-10 hidden md:block">
          <img src="../assets//profile-image.png" alt="" class="" />
        </div>
      </div>
    </div>
    <div class="flex items-center" v-if="users">
      <button
        :disabled="pagination.currentPage == 1 ? true : false"
        @click.prevent="previous"
        class="flex items-center font-semibold pagination-btn"
      >
        <i class="uil uil-angle-left-b"></i>Previous&nbsp;&nbsp;
      </button>
      <div class="px-3">
        <p class="text-sm">
          Page {{ pagination.currentPage }} out of {{ pagination.totalPages }}
        </p>
      </div>
      <button
        @click.prevent="next"
        :disabled="
          pagination.currentPage == pagination.totalPages ? true : false
        "
        class="flex items-center font-semibold pagination-btn px-2"
      >
        Next&nbsp;&nbsp;<i class="uil uil-angle-right-b"></i>
      </button>
    </div>
    <div class="flex my-5 w-full justify-center items-center bg-red-100">
      <loader :loading="loading" class="self-center" />
    </div>
    <div class="bg-white flex flex-col p-3 justify-center rounded-lg my-10">
      <h1 class="header6 py-4 text-primary pb-4">Talent Pool</h1>
      <div class="table-container">
        <table
          class="
            table-auto
            cursor-pointer
            talent-table
            para1
            divide-y-4 divide-gray-light divide-solid
          "
        >
          <thead class="">
            <tr class="text-left">
              <th class="w-1/12 h-14">S/N</th>
              <th class="w-2/12">Name</th>
              <th class="w-3/12">Email <Address></Address></th>
              <th class="w-32/12">Stack</th>
              <th class="w-2/12">Status</th>
              <th class="w-1/12">Actions</th>
            </tr>
          </thead>
          <tbody class="divide-y-4 divide-gray-light divide-solid">
            <tr
              v-for="(user, index) in users"
              :key="user.index"
              class="h-12 mod-rel"
            >
              <td class="font-bold">{{ index + 1 }}</td>
              <td class="font-bold">
                {{ user.lastName }} {{ user.firstName }}
              </td>
              <td>{{ user.email }}</td>
              <td>{{ user.primaryStack }}</td>
              <td>
                <p v-if="!user.isApproved">Not Approved</p>
                <p v-if="user.isApproved">
                  {{ user.isApproved ? "Approved" : "Withdrawn" }}
                </p>
              </td>
              <td class="text-center" @click="talentModal(index)">
                <i class="uil uil-ellipsis-h text-2xl"></i>
              </td>
              <div class="mod-rel" v-if="talentMiniModal">
                <div
                  v-show="miniTalentModal == index"
                  class="modal w-40 rounded-md shadow-lg bg-white py-3 mt-10"
                >
                  <ul class="">
                    <li class="py-2 pl-3">
                      <router-link :to="`/dashboard/user/${user._id}`"
                        >View User Details</router-link
                      >
                    </li>
                    <li
                      v-if="!user.isApproved"
                      class="py-2 pl-3"
                      @click="approveModal"
                    >
                      Approve User
                    </li>
                    <li
                      v-if="user.isApproved"
                      class="py-2 pl-3"
                      @click="disapproveModal"
                    >
                      Disapprove User
                    </li>
                  </ul>

                  <!-- Approved Modal -->
                  <div
                    v-if="approve"
                    class="
                      backdrop
                      flex flex-no-wrap
                      justify-center
                      items-center
                    "
                    @click.self="approveModal"
                  >
                    <div
                      class="
                        flex flex-col flex-no-wrap
                        bg-white
                        rounded-lg
                        modal-container
                      "
                    >
                      <div class="content text-center m-5">
                        <div class="flex flex-col justify-center my-5">
                          <div class="mx-auto">
                            <img src="../assets/Approved.png" alt="" class="" />
                          </div>
                          <span class="flex py-2 justify-center text-lg">
                            <p class="font-medium pr-2">Approve</p>
                            <p class="font-semibold">
                              {{ user.lastName }} {{ user.firstName }}
                            </p>
                          </span>
                        </div>
                        <div class="flex justify-center gap-x-4">
                          <button
                            class="
                              bg-gray-200
                              rounded-lg
                              px-5
                              py-4
                              text-gray-medDark
                              font-semibold
                              hover:bg-error hover:text-white
                              cursor-pointer
                              outline-none
                            "
                            @click.prevent="approveModal"
                          >
                            Cancel
                          </button>
                          <button
                            class="
                              bg-success
                              rounded-md
                              py-4
                              text-white
                              font-semibold
                              px-5
                              cursor-pointer
                              outline-none
                              flex
                              justify-center
                              self-center
                            "
                            @click="approveUser(user._id)"
                          >
                            Approve User &nbsp;
                            <loader :loading="approving" class="self-center" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Disapproved Modal -->
                  <div
                    v-if="disapprove"
                    class="
                      backdrop
                      flex flex-no-wrap
                      justify-center
                      items-center
                    "
                    @click.self="disapproveModal"
                  >
                    <div
                      class="
                        flex flex-col flex-no-wrap
                        bg-white
                        rounded-lg
                        modal-container
                      "
                    >
                      <div class="content text-center m-5">
                        <div class="flex flex-col justify-center my-5">
                          <div class="mx-auto">
                            <img
                              src="../assets/disapprove.png"
                              alt=""
                              class=""
                            />
                          </div>
                          <span class="flex py-2 justify-center text-lg">
                            <p class="font-medium pr-2">Disapprove</p>
                            <p class="font-semibold">
                              {{ user.lastName }} {{ user.firstName }}
                            </p>
                          </span>
                        </div>
                        <!-- Not included -->
                        <!-- <div class="text-left m-4">
                        <p class="font-semibold pb-2">
                          Reasons for disapproval:
                        </p>
                        <input
                          type="text"
                          class="
                            bg-gray-light
                            text-gray-dark
                            w-full
                            p-3
                            outline-none
                          "
                          placeholder="Type reason here..."
                          name=""
                          id=""
                        />
                      </div> -->
                        <div class="flex justify-center gap-x-4">
                          <button
                            class="
                              bg-gray-200
                              rounded-lg
                              w-40
                              py-4
                              text-gray-medDark
                              font-semibold
                              cursor-pointer
                              outline-none
                            "
                            @click.prevent="disapproveModal"
                          >
                            Cancel
                          </button>
                          <button
                            class="
                              bg-error
                              rounded-md
                              py-4
                              text-white
                              font-semibold
                              w-40
                              cursor-pointer
                              outline-none
                            "
                            @click="disApproveUser(user._id)"
                          >
                            Disapprove User
                            <loader
                              :loading="disapproving"
                              class="self-center"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style scoped>
ul {
  list-style-type: none;
}
.nav-icon {
  /* back */
}
.icon-gray {
  color: #535151;
}
.modal ul li:hover {
  background-color: #24beca;
  color: #ffffff;
}
.mod-rel {
  position: relative;
}
.modal {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 88;
}
.talent-table {
  border-collapse: collapse;
  margin-top: 20px;
  width: 100%;
  border-radius: 6px;
  /* overflow: hidden; */
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100vh;
  z-index: 99999;
}
.backdropMod {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100vh;
  z-index: 9;
}
.modal-container {
  min-width: 400px;
  max-width: 400px;
  overflow: hidden;
  min-height: 300px;
}

@media screen and (max-width: 640px) {
  .talent-table {
    width: 280%;
  }
  .table-container {
    max-width: 100%;
    overflow-x: auto;
  }
  .modal-container {
    min-width: 90%;
    max-width: 90%;
    overflow: hidden;
    min-height: 300px;
  }
}
</style>
<script>
import loader from "@/components/loading.vue";
export default {
  components: {
    loader,
  },
  data() {
    return {
      disapprove: false,
      approve: false,
      modal: false,
      miniTalentModal: false,
      talentMiniModal: false,
      currentPage: 1,
      searchInput: "",
    };
  },
  async mounted() {
    await this.$store.dispatch("getAllUsers", {
      page: 1,
    });
  },
  computed: {
    users() {
      return this.$store.getters.getAllUsers;
    },
    pagination() {
      return this.$store.getters.getPagination;
    },
    loading() {
      return this.$store.getters.gettingAllUser;
    },
    approving() {
      return this.$store.getters.approvingUser;
    },
    disapproving() {
      return this.$store.getters.disapprovingUser;
    },
  },
  methods: {
    next() {
      this.$store.dispatch("getAllUsers", { page: +this.currentPage + 1 });
    },
    previous() {
      this.$store.dispatch("getAllUsers", { page: +this.currentPage - 1 });
    },
    talentModal(index) {
      this.miniTalentModal = index;
      this.talentMiniModal = !this.talentMiniModal;
    },
    approveModal() {
      this.approve = !this.approve;
    },
    disapproveModal() {
      this.disapprove = !this.disapprove;
    },
    async approveUser(id) {
      await this.$store.dispatch("approveUser", id);
      this.approve = false;
    },
    async disApproveUser(id) {
      await this.$store.dispatch("disApproveUser", id);
      this.disapprove = !this.disapprove;
    },
    search() {
      this.$store.dispatch("getSearchedUser", {
        search: this.searchInput,
        page: 1,
      });
    },
  },
};
</script>