<template>
  <div class="my-10">
    <div class="">
      <router-link
        class="flex cursor-pointer font-medium text-xl"
        to="/dashboard/"
      >
        <i class="uil uil-arrow-left text-2xl"></i>
        <p class="self-center pl-2">Go Back</p>
      </router-link>
    </div>
    <div class="mt-5">
      <div
        class="
          bg-white
          rounded-lg
          px-8
          lg:px-20
          py-5
          lg:py-10
          text-gray-medDark
          w-full
        "
      >
        <div class="md:flex justify-between">
          <div class="flex">
            <div class="flex">
              <div
                class="
                  flex
                  justify-center
                  w-28
                  h-28
                  rounded-full
                  bg-gray-medium
                  text-white
                "
              >
                <img
                  class="w-28 h-28 rounded-full"
                  :src="user.avatarUrl"
                  alt=""
                  v-if="user.avatarUrl"
                />
                <i
                  class="uil uil-user self-center font-semibold text-3xl"
                  v-if="!user.avatarUrl"
                ></i>
              </div>
              <div class="self-center pl-5">
                <h1 class="font-bold text-2xl capitalize">
                  {{ user.lastName }} {{ user.firstName }}
                </h1>
                <a
                  :href="'mailto:' + user.email"
                  class="text-primary text-lg"
                  >{{ user.email }}</a
                >
              </div>
            </div>
          </div>
          <!-- <div class="self-center my-5">
            <button
              class="
                bg-secondary
                md:ml-auto
                block
                mb-4
                text-white
                px-5
                py-2
                rounded-md
                font-medium
              "
            >
              {{ user.isApproved ? "Approved" : "Not Approved" }}
            </button>
          </div> -->
        </div>
        <div class="md:flex">
          <div class="lg:w-6/12">
            <ul class="my-4">
              <h3 class="font-bold text-2xl text-gray-medDark mr-2 mt-4">
                Personal Information
              </h3>
              <li class="pt-5 flex text-lg">
                <h3 class="font-medium text-gray-medDark mr-2">
                  Phone Number:
                </h3>
                <p v-if="user.phoneNumber">{{ user.phoneNumber }}</p>
                <p v-if="!user.phoneNumber">No Phone Number</p>
              </li>
              <li class="pt-5 flex text-lg">
                <h3 class="font-medium text-gray-medDark mr-2">Nationality:</h3>
                {{ user.nationality }}
              </li>
              <li class="pt-5 flex text-lg">
                <h3 class="font-medium text-gray-medDark mr-2">Location:</h3>
                Nigeria
              </li>
              <li class="pt-5 flex text-lg">
                <h3 class="font-medium text-gray-medDark mr-2">
                  English Profieciency:
                </h3>
                {{ user.englishProficiency }}
              </li>
              <li class="pt-5 flex text-lg">
                <h3 class="font-medium text-gray-medDark mr-2">
                  Match Status:
                </h3>
                {{ user.englishProficiency }}
              </li>
            </ul>
            <button
              class="
                bg-primary
                text-white
                px-5
                py-3
                font-medium
                text-lg
                my-8
                rounded-md
                cursor-pointer
              "
              @click="matchModal"
            >
              Match User with company
            </button>
          </div>
          <div
            class="
              self-center
              md:border-l-2
              border-gray-medium
              md:pl-10
              lg:w-6/12
            "
          >
            <div class="flex justify-between my-5">
              <h1 class="font-bold text-2xl">Résumé</h1>
              <div class="text-right">
                <a
                  :href="user.resumeFileUrl"
                  class="text-primary font-medium text-lg"
                  >Open Resume Url</a
                >
              </div>
            </div>
            <div class="flex">
              <div class="flex">
                <div class="">
                  <img src="../assets/pdf.png" alt="" />
                </div>
                <div class="self-center pl-5">
                  <h1 class="font-semibold text-2xl">My Cv-2021</h1>
                  <p class="text-gray-medium">Sept 12, 2021</p>
                </div>
              </div>
            </div>
            <ul class="my-3">
              <li class="pt-5 flex text-lg">
                <h3 class="font-bold text-2xl text-gray-medDark mr-2">
                  Full Stack Developer
                </h3>
              </li>
              <li class="pt-5 flex text-lg">
                <h3 class="font-medium text-gray-medDark mr-2">
                  Stack Experience:
                </h3>
                {{ user.yearsOfExperienceWithPrimaryStack }} years
              </li>
              <li class="pt-5 flex text-lg">
                <h3 class="font-medium text-gray-medDark mr-2">
                  Total Work Experience:
                </h3>
                {{ user.yearsOfWorkingExperience }} years
              </li>
              <li class="pt-5 flex text-lg">
                <h3 class="font-medium text-gray-medDark mr-2">
                  Portfolio Link:
                </h3>
                <a :href="user.portfolioLink" class="text-primary">{{
                  user.portfolioLink
                }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Match Modal -->
    <div
      v-if="match"
      class="backdrop flex flex-no-wrap justify-center items-center"
      @click.self="matchModal"
    >
      <div
        class="flex flex-col flex-no-wrap bg-white rounded-lg modal-container"
      >
        <div class="content m-5">
          <div class="text-right">
            <button
              class="
                text-xl
                pb-2
                text-gray-medDark
                font-medium
                cursor-pointer
                outline-none
              "
              @click.prevent="matchModal"
            >
              X
            </button>
          </div>
          <div class="flex flex-col justify-center mb-5">
            <span class="flex py-2 text-left text-2xl">
              <p class="font-bold pr-2">Match User with Company</p>
            </span>
          </div>
          <form action="">
            <div class="text-left mb-5">
              <p class="font-semibold pb-2">Company Name:</p>
              <input
                type="text"
                v-model="companyName"
                class="bg-gray-light text-gray-dark w-full p-3 outline-none"
                placeholder="Type Company Name here..."
                name=""
                id=""
                required
              />
            </div>
            <div class="text-left my-5">
              <p class="font-semibold pb-2">Company Website:</p>
              <input
                type="text"
                v-model="companyWebsite"
                class="bg-gray-light text-gray-dark w-full p-3 outline-none"
                placeholder="Type Company Website here..."
                name=""
                id=""
                required
              />
            </div>
            <div class="flex justify-center gap-x-4 mt-8">
              <button
                class="
                  bg-primary
                  rounded-md
                  py-4
                  text-white
                  font-semibold
                  w-full
                  cursor-pointer
                  outline-none
                "
                @click.prevent="matchUser"
              >
                Match User With Company
                <loader :loading="loading" class="self-center" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import loader from "@/components/loading.vue";
export default {
  data() {
    return {
      match: false,
      userId: "",
      companyName: "",
      companyWebsite: "",
      user: {},
    };
  },
  components: {
    loader,
  },
  mounted() {
    this.getUserData();
  },
  computed: {
    loading() {
      return this.$store.getters.matchingUser;
    },
  },
  methods: {
    matchModal() {
      this.match = !this.match;
    },

    async getUserData() {
      await axios
        .get(
          `https://chuuse-node.herokuapp.com/api/v1/user/${this.$route.params.id}`,
          {
            headers: {
              "x-id-key": JSON.parse(window.localStorage.getItem("e-l-key")),
            },
          }
        )
        .then((res) => {
          this.user = res.data.data;
          console.log(this.user);
        });
    },
    async matchUser() {
      const matchingDetails = {
        userId: this.$route.params.id,
        company_name: this.companyName,
        website: this.companyWebsite,
      };
      await this.$store.dispatch("matchUser", matchingDetails);
      this.companyName = "";
      this.companyWebsite = "";
      this.match = false;
    },
  },
};
</script>

<style>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100vh;
  z-index: 99999;
}
.modal-container {
  min-width: 400px;
  max-width: 400px;
  overflow: hidden;
  min-height: 320px;
  @include sm {
    min-width: 95%;
    width: 95%;
  }
}
</style>