import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import Overview from '../views/overview.vue'
import allTalent from '../views/all-talents.vue'
import approvedTalent from '../views/approved-talents.vue'
import user from '../views/user.vue'
import blogPost from '../views/blog-post.vue'
// import store from '../store/modules/auth'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },

  {
    path: '/dashboard',
    name: 'About',
    component: Dashboard,

    children: [
      {
        path: '/dashboard/',
        name: 'overview',
        component: Overview
      },
      {
        path: '/dashboard/all-talents',
        name: 'all-talents',
        component: allTalent
      },
      {
        path: '/dashboard/approved-talents',
        name: 'approved-talents',
        component: approvedTalent
      },
      {
        path: '/dashboard/user/:id',
        name: 'user',
        component: user
      },
      {
        path: '/dashboard/blog-post',
        name: 'blogPost',
        component: blogPost
      },
    ],
    // meta: { requiresAuth: true },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})





export default router
