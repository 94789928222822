<template>
  <div v-if="loading" :style="mai" class="lds-ring">
    <div :style="style"></div>
    <div :style="style"></div>
    <div :style="style"></div>
    <div :style="style"></div>
  </div>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    color: {
      type: String,
      default: "#ffffff",
    },
    radius: {
      type: String,
      default: "13",
    },
  },
  computed: {
    style() {
      return `border: 2px solid ${this.color ? this.color : "#FFFFFF"};
      border-color: ${this.color} transparent transparent transparent;
      width: ${this.radius}px;
      height: ${this.radius}px;
      `;
    },
    mai() {
      return `width: ${this.radius}px;
      height: ${this.radius}px;`;
    },
  },
};
</script>
<style  scoped>
.lds-ring {
  display: inline-block;
  position: relative;
  margin-right: 6px;
  margin-top: -3px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  margin: 2px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* generated by https://loading.io/ */
</style>
