<template>
  <div class="my-10">
    <div class="md:flex md:justify-between mt-5">
      <h1 class="text-2xl font-semibold mb-4">Blog Post</h1>
      <!-- Search and Profile -->
      <div class="md:flex gap-x-4 md:justify-end items-center md:mt-0">
        <div class="bg-gray-light text-gray-medDark flex rounded-xl lg:w-64">
          <i class="uil uil-search text-lg px-2 self-center"></i>
          <input
            type="search"
            class="bg-gray-light outline-none py-3"
            name=""
            placeholder="Search"
          />
        </div>
        <div class="w-10 hidden md:block">
          <img src="../assets//profile-image.png" alt="" class="" />
        </div>
      </div>
    </div>
    <div
      class="
        bg-white
        table-container
        flex flex-col
        p-3
        justify-center
        rounded-lg
        mt-10
      "
    >
      <table
        class="
          table-auto
          talent-table
          cursor-pointer
          para1
          divide-y-4 divide-gray-light divide-solid
        "
      >
        <thead class="h-12">
          <tr class="text-left border-b-2 border-gray-100">
            <th class="md:w-1/12 w-2/12">No.</th>
            <th class="md:w-4/12">Blog Title</th>
            <th class="md:w-2/12 w-3/12">Post Date</th>
            <th class="md:w-3/12 hidden md:block pt-4">Category</th>
            <th class="md:w-1/12 w-2/12">Views</th>
            <th class="md:w-1/12">Actions</th>
          </tr>
        </thead>
        <tbody class="divide-y-2 divide-gray-light divide-solid">
          <tr
            class="h-14 mod-rel"
            v-for="(post, index) in posts"
            :key="post.index"
          >
            <td class="">{{ index + 1 }}</td>
            <td>{{ post.title }}</td>
            <td>{{ post.date }}</td>
            <td class="hidden lg:block py-2">
              <button class="bg-secondary text-white font-bold rounded-md p-2">
                {{ post.category }}
              </button>
            </td>
            <td>1k</td>
            <td class="text-center" @click="postModal(index)">
              <i class="uil uil-ellipsis-h text-2xl"></i>
            </td>
            <div class="mod-rel" v-if="blogmodal">
              <div
                v-show="miniBlogModal == index"
                class="modal w-40 rounded-md shadow-lg bg-white py-3 mt-5"
              >
                <ul class="">
                  <li class="py-2 pl-3">Edit Post</li>
                  <li class="py-2 pl-3">Delete Post</li>
                </ul>
              </div>
            </div>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<style scoped>
.modal ul li:hover {
  background-color: #24beca;
  color: #ffffff;
}
.mod-rel {
  position: relative;
}
.modal {
  position: absolute;
  right: 0px;
  top: 15px;
  z-index: 88;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100vh;
  z-index: 99999;
}
.modal-container {
  min-width: 400px;
  max-width: 400px;
  overflow: hidden;
  min-height: 300px;
  @include sm {
    min-width: 95%;
    width: 95%;
  }
}
@media screen and (max-width: 640px) {
  .talent-table {
    width: 200%;
  }
  .table-container {
    max-width: 100%;
    overflow-x: auto;
  }
}
</style>
<script>
export default {
  data() {
    return {
      blogmodal: false,
      minimodal: false,
      miniBlogModal: false,
      posts: [
        {
          title: "Tips & Recommendation on Design Project",
          date: "0ct 30, 2021",
          category: "Web development",
          Views: "1K",
        },
        {
          title: "Tips & Recommendation on Design Project",
          date: "0ct 30, 2021",
          category: "Web development",
          Views: "1K",
        },
        {
          title: "Tips & Recommendation on Design Project",
          date: "0ct 30, 2021",
          category: "Web development",
          Views: "1K",
        },
        {
          title: "Tips & Recommendation on Design Project",
          date: "0ct 30, 2021",
          category: "Web development",
          Views: "1K",
        },
      ],
    };
  },
  methods: {
    postModal(index) {
      this.miniBlogModal = index;
      this.blogmodal = !this.blogmodal;
    },
  },
};
</script>