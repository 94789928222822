//store/modules/auth.js
import axios from 'axios';
import router from '../../router/index'

export const baseUrl = "https://chuuse-node.herokuapp.com/api/v1/admin"

const state = {
	accessToken: null,
	identification: '',
	approvedUsers: [],
	allUsers: [],
	getAllUser: false,
	loading: false,
	message: null,
	approving: false,
	disapproving: false,
	pagination: [],
	matching: true
};

const getters = {
	getToken: (state) => state.accessToken,
	getLoginError: (state) => state.loginError,
	getApprovedUsers: (state) => state.approvedUsers,
	getAllUsers: (state) => state.allUsers,
	gettingAllUser: (state) => state.getAllUser,
	getPagination: (state) => state.pagination,
	gettingUsers: (state) => state.loading,
	approvingUser: (state) => state.approving,
	disapprovingUser: (state) => state.disapproving,
	getErrMessage: (state) => state.message,
	matchingUser: (state) => state.matching
};
const actions = {
	login: async (context, payload) => {
		const { email, password } = payload;
		const data = { email, password };
		const url = `${baseUrl}/login`;
		context.commit("setLoading", true)
		await axios.post(url, data)
			.then((res) => {
				window.localStorage.setItem('e-l-key', JSON.stringify(res.headers["x-id-key"]))
				context.commit('updateAccessToken', JSON.parse(window.localStorage.getItem('e-l-key')));
				context.commit("setErrMessage", false)
			})
			.catch((err) => {
				context.commit("setErrMessage", err.response.data.message)
			})
			.finally(() => {
				context.commit("setLoading", false);
				router.push('/dashboard')
			});
	},
	logout({ commit }) {
		localStorage.removeItem('e-l-key');
		commit('logout');
	},
	getApprovedUser: (context) => {
		axios.get(`${baseUrl}/get/approved`, {
			headers: {
				"x-id-key": JSON.parse(window.localStorage.getItem('e-l-key'))
			}
		})
			.then((res) => {
				context.commit('approvedUsers', res.data.data)
				return { status: "success", message: "successful registration!" }
			})
	},
	getAllUsers: (context, payload) => {
		context.commit("setGettingAllUser", true)
		const { page = 1 } = payload;

		axios.get(`${baseUrl}/users?page=${page}&limit=10&search=`, {
			headers: {
				"x-id-key": JSON.parse(window.localStorage.getItem('e-l-key'))
			}
		})
			.then((res) => {
				context.commit('pagination', res.data.data)
				context.commit('allUsers', res.data.data.data)
			})
			.finally(() => {
				context.commit('setGettingAllUser', false);
			});
	},
	getSearchedUser: (context, payload) => {
		// context.commit("setGettingAllUser", true)
		const { page, search } = payload;

		axios.get(`${baseUrl}/users?page=${page}&limit=10&search=${search}`, {
			headers: {
				"x-id-key": JSON.parse(window.localStorage.getItem('e-l-key'))
			}
		})
			.then((res) => {
				context.commit('pagination', res.data.data)
				context.commit('allUsers', res.data.data.data)
				return { status: "success", message: "successful registration!" }
			})
			.finally(() => {
				// context.commit('setGettingAllUser', false);
			});
	},
	approveUser: async (context, id) => {
		const data = { id }
		const url = `${baseUrl}/user/approve`;
		context.commit('approvingUser', true);
		await axios.post(url, data, {
			headers: {
				"x-id-key": JSON.parse(window.localStorage.getItem('e-l-key'))
			}
		})
			.then((res) => {
				console.log(res.data.data)
			})
			.catch(() => {
				// context.commit('setError', err.response.data.message);
			})
			.finally(() => {
				context.commit('approvingUser', false);
			});
		return true;
	},
	disApproveUser: async (context, id) => {
		const data = { id }
		const url = `${baseUrl}/user/disapprove`;
		context.commit('disapprovingUser', true);
		await axios.post(url, data, {
			headers: {
				"x-id-key": JSON.parse(window.localStorage.getItem('e-l-key'))
			}
		})
			.then((res) => {
				console.log(res.data.data)
			})
			.catch(() => {
				// context.commit('setError', err.response.data.message);
			})
			.finally(() => {
				context.commit('disapprovingUser', false);
			});
		return true;
	},
	matchUser: async (context, matchingDetails) => {
		const { userId, company_name, website } = matchingDetails
		const data = { userId, company_name, website }
		const url = `${baseUrl}/user/match`;
		context.commit('setMatching', true);
		await axios.post(url, data, {
			headers: {
				"x-id-key": JSON.parse(window.localStorage.getItem('e-l-key'))
			}
		})
			.then((res) => {
				console.log(res.data.data)
			})
			.catch(() => {
				// context.commit('setError', err.response.data.message);
			})
			.finally(() => {
				context.commit('setMatching', false);
			});
		return true;
	},
};
const mutations = {
	setErrMessage: (state, errMessage) => {
		state.message = errMessage
	},
	updateAccessToken: (state, accessToken) => {
		state.accessToken = accessToken;
	},
	setKey: (state, key) => {
		state.identification = key
	},
	approvedUsers: (state, detail) => {
		state.approvedUsers = detail
	},
	allUsers: (state, detail) => {
		state.allUsers = detail
	},
	setGettingAllUser: (state, status) => {
		state.getAllUsers = status
	},
	pagination: (state, pagination) => {
		state.pagination = pagination
	},
	setLoading: (state, status) => {
		state.loading = status;
	},
	approvingUser: (state, status) => {
		state.approving = status;
	},
	disapprovingUser: (state, status) => {
		state.disapproving = status;
	},
	setMatching: (state, status) => {
		state.matching = status
	},
	logout: (state) => {
		state.accessToken = null;
	}
};
export default {
	state,
	getters,
	actions,
	mutations
};
