<template>
  <div class="lg:h-screen">
    <div class="md:flex md:justify-between mt-5">
      <h1 class="text-2xl font-semibold mb-4">Approved Talents</h1>
      <!-- Search and Profile
      <div class="md:flex gap-x-4 md:justify-end md:mt-0">
        <div class="bg-gray-light text-gray-medDark flex rounded-xl lg:w-64">
          <i class="uil uil-search text-lg px-2 self-center"></i>
          <input
            type="search"
            class="bg-gray-light outline-none py-3 md:py-0"
            name=""
            placeholder="Search"
          />
        </div>
        <div class="w-10 hidden md:block">
          <img src="../assets//profile-image.png" alt="" class="" />
        </div>
      </div> -->
    </div>
    <div
      class="
        bg-white
        table-container
        flex flex-col
        p-3
        justify-center
        rounded-lg
        my-10
      "
    >
      <table
        class="
          table-auto
          cursor-pointer
          talent-table
          para1
          divide-y-4 divide-gray-light divide-solid
        "
      >
        <thead class="">
          <tr class="text-left">
            <th class="md:w-1/12 h-14">S/N</th>
            <th class="md:w-2/12">Name</th>
            <th class="md:w-3/12 hidden md:block pt-4">
              Email <Address></Address>
            </th>
            <th class="md:w-3/12">Stack</th>
            <th class="md:w-2/12">Matched</th>
            <th class="md:w-1/12">Actions</th>
          </tr>
        </thead>
        <tbody class="divide-y-4 divide-gray-light divide-solid">
          <tr
            class="h-12 mod-rel"
            v-for="(approvedUser, index) in approvedUsers"
            :key="approvedUser.index"
          >
            <td class="font-bold">{{ index + 1 }}</td>
            <td class="font-bold">
              {{ approvedUser.lastName }} {{ approvedUser.firstName }}
            </td>
            <td class="hidden md:block pt-3">{{ approvedUser.email }}</td>
            <td>{{ approvedUser.primaryStack }}</td>
            <td>
              <p v-if="approvedUser.matchDetails.company_name">
                {{ approvedUser.matchDetails.company_name }}
              </p>
              <p v-if="!approvedUser.matchDetails.company_name">N/A</p>
            </td>
            <td class="text-center" @click="talentModal(index)">
              <i class="uil uil-ellipsis-h text-2xl"></i>
            </td>
            <div class="mod-rel" v-if="talentMiniModal">
              <div
                v-show="miniTalentModal == index"
                class="modal w-40 rounded-md shadow-lg bg-white py-3 mt-10"
              >
                <ul class="">
                  <li class="py-2 pl-3">
                    <router-link :to="`/dashboard/user/${approvedUser._id}`"
                      >View User Details</router-link
                    >
                  </li>
                  <li class="py-2 pl-3" @click="deleteModal">Delete User</li>
                </ul>

                <!-- Delete User Modal -->
                <div
                  v-if="deleteUser"
                  class="backdrop flex flex-no-wrap justify-center items-center"
                  @click.self="deleteModal"
                >
                  <div
                    class="
                      flex flex-col flex-no-wrap
                      bg-white
                      rounded-lg
                      modal-container
                    "
                  >
                    <div class="content text-center m-5">
                      <div class="flex flex-col justify-center my-5">
                        <div class="mx-auto mb-4">
                          <img src="../assets/delete.png" alt="" class="" />
                        </div>
                        <span class="pt-3 text-lg">
                          <p class="text-error text-xl font-semibold">
                            Delete User!!
                          </p>
                          <p class="py-2">
                            Are you sure you want to delete this user?
                          </p>
                        </span>
                      </div>
                      <div class="flex justify-center gap-x-4">
                        <button
                          class="
                            bg-gray-200
                            rounded-lg
                            w-40
                            py-4
                            text-gray-medDark
                            font-semibold
                            cursor-pointer
                            outline-none
                          "
                          @click.prevent="deleteModal"
                        >
                          Cancel
                        </button>
                        <button
                          class="
                            bg-error
                            rounded-md
                            py-4
                            text-white
                            font-semibold
                            w-40
                            cursor-pointer
                            outline-none
                          "
                        >
                          Delete User
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<style scoped>
.modal ul li:hover {
  background-color: #24beca;
  color: #ffffff;
}
.mod-rel {
  position: relative;
}
.modal {
  position: absolute;
  right: 0px;
  top: 20px;
  z-index: 88;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100vh;
  z-index: 99999;
}
.modal-container {
  min-width: 400px;
  max-width: 400px;
  overflow: hidden;
  min-height: 300px;
}

.talent-table {
  border-collapse: collapse;
  margin-top: 20px;
  width: 100%;
  border-radius: 6px;
}
@media screen and (max-width: 540px) {
  .talent-table {
    width: 200%;
  }
  .table-container {
    max-width: 100%;
    overflow-x: auto;
  }
  .modal-container {
    min-width: 90%;
    max-width: 90%;
    overflow: hidden;
    min-height: 300px;
  }
}
</style>
<script>
export default {
  data() {
    return {
      deleteUser: false,
      talentMiniModal: false,
      miniTalentModal: false,
      aTalents: [
        {
          id: 1,
          name: "Joy Martins",
          email: "joy@gmail.com",
          stack: "Full Stack Developer",
          match: "N/A",
        },
        {
          id: 2,
          name: "James Stone",
          email: "Stone34@yahoo.com",
          stack: "Data Analyst",
          match: "Paystack",
        },
        {
          id: 3,
          name: "Alyssa Cooper",
          email: "Alyssa@gmail.com",
          stack: "UI/UX Designer",
          match: "Piggyvest",
        },
        {
          id: 4,
          name: "Hannah Simmons",
          email: "Hannah@gmail.com",
          stack: "Front End Developer",
          match: "Chuuse",
        },
        {
          id: 5,
          name: "Addison Carte",
          email: "Addison08@gmail.com",
          stack: "Back End Developer",
          match: "N/A",
        },
        {
          id: 6,
          name: "Samuel Roberts",
          email: "SamRob@gmail.com",
          stack: "Data Scientist",
          match: "Piggyvest",
        },
        {
          id: 7,
          name: "Alyssa Cooper",
          email: "Alyssa@gmail.com",
          stack: "UI/UX Designer",
          match: "Piggyvest",
        },
        {
          id: 8,
          name: "Hannah Simmons",
          email: "Hannah@gmail.com",
          stack: "Front End Developer",
          match: "Chuuse",
        },
        {
          id: 8,
          name: "Addison Carte",
          email: "Addison08@gmail.com",
          stack: "Back End Developer",
          match: "N/A",
        },
      ],
    };
  },

  async mounted() {
    await this.$store.dispatch("getApprovedUser");
    console.log("proxy: ", this.approvedUsers);
  },
  computed: {
    approvedUsers() {
      return this.$store.getters.getApprovedUsers;
    },
  },
  methods: {
    talentModal(index) {
      this.miniTalentModal = index;
      this.talentMiniModal = !this.talentMiniModal;
    },
    deleteModal() {
      this.deleteUser = !this.deleteUser;
    },
  },
};
</script>