<template>
  <div class="dashboard">
    <div class="flex mx-2 lg:mx-5">
      <div class="hidden lg:flex mx-5 w-2/12 relative xl:h-screen">
        <ul
          class="
            fixed
            h-screen
            flex flex-col
            content-between
            mt-10
            list-none
            text-gray-medDark
          "
        >
          <ul class="flex flex-col gap-y-12">
            <li class="mb-10 w-full">
              <img src="../assets/chuuselogo.png" alt="" class="w-40 h-full object-cover" />
            </li>
            <li class="flex group">
              <div class="bg-gray rounded-md group-hover:bg-primary p-2 mr-3">
                <i
                  class="
                    uil uil-estate
                    text-xl
                    icon-gray
                    group-hover:text-white
                  "
                ></i>
              </div>
              <router-link
                to="/dashboard"
                class="
                  text-lg
                  font-medium
                  self-center
                  group-hover:text-primary
                  cursor-pointer
                "
              >
                Overview
              </router-link>
            </li>
            <li class="flex group">
              <div class="bg-gray rounded-md group-hover:bg-primary p-2 mr-3">
                <i
                  class="
                    uil uil-user-check
                    text-xl
                    icon-gray
                    group-hover:text-white
                  "
                ></i>
              </div>
              <router-link
                to="/dashboard/approved-talents"
                class="
                  text-lg
                  font-medium
                  self-center
                  group-hover:text-primary
                  cursor-pointer
                "
              >
                Approved Talents
              </router-link>
            </li>
            <li class="flex group">
              <div class="bg-gray rounded-md group-hover:bg-primary p-2 mr-3">
                <i
                  class="
                    uil uil-users-alt
                    text-xl
                    icon-gray
                    group-hover:text-white
                  "
                ></i>
              </div>
              <router-link
                to="/dashboard/all-talents"
                class="
                  text-lg
                  font-medium
                  self-center
                  group-hover:text-primary
                  cursor-pointer
                "
              >
                All Talents
              </router-link>
            </li>
            <li class="flex group">
              <div class="bg-gray rounded-md group-hover:bg-primary p-2 mr-3">
                <i
                  class="
                    uil uil-postcard
                    text-xl
                    icon-gray
                    group-hover:text-white
                  "
                ></i>
              </div>
              <router-link
                to="/dashboard/blog-post"
                class="
                  text-lg
                  font-medium
                  self-center
                  group-hover:text-primary
                  cursor-pointer
                "
              >
                Posts
              </router-link>
            </li>
          </ul>
          <div class="absolute bottom-10">
            <li
              class="
                flex
                group
                text-red-600 text-lg
                font-medium
                cursor-pointer
                mb-10
              "
              @click="logOut"
            >
              <p class="">Log Out</p>
              <i class="ml-2 uil uil-signout"></i>
            </li>
          </div>
        </ul>
      </div>
      <div class="lg:w-10/12 w-full rounded-3xl bg-gray mt-10 px-5 lg:px-10">
        <router-view></router-view>
      </div>
    </div>
    <!-- Mobile nav -->
    <div class="flex flex-col lg:hidden slot-holder">
      <div v-if="isOpen" class="sidebar-backdrop" @click="toggleMenu">
        <transition name="slide">
          <div v-if="isOpen" class="">
            <ul
              class="
                mobile-menu-new
                pl-5
                bg-white
                h-screen
                shadow-sm
                flex flex-col
                gap-y-10
              "
            >
              <ul class="flex flex-col gap-y-12">
                <li class="my-10 w-32">
                  <img src="../assets/chuuselogo.png" alt="" />
                </li>
                <li class="flex group mb-5">
                  <div
                    class="bg-gray rounded-md group-hover:bg-primary p-2 mr-3"
                  >
                    <i
                      class="
                        uil uil-estate
                        text-lg
                        icon-gray
                        group-hover:text-white
                      "
                    ></i>
                  </div>
                  <router-link
                    to="/dashboard"
                    class="
                      text-lg
                      self-center
                      group-hover:text-primary
                      cursor-pointer
                    "
                  >
                    Overview
                  </router-link>
                </li>
                <li class="flex group mb-5">
                  <div
                    class="bg-gray rounded-md group-hover:bg-primary p-2 mr-3"
                  >
                    <i
                      class="
                        uil uil-user-check
                        text-lg
                        icon-gray
                        group-hover:text-white
                      "
                    ></i>
                  </div>
                  <router-link
                    to="/dashboard/approved-talents"
                    class="
                      text-lg
                      self-center
                      group-hover:text-primary
                      cursor-pointer
                    "
                  >
                    Approved Talents
                  </router-link>
                </li>
                <li class="flex group mb-5">
                  <div
                    class="bg-gray rounded-md group-hover:bg-primary p-2 mr-3"
                  >
                    <i
                      class="
                        uil uil-users-alt
                        text-lg
                        icon-gray
                        group-hover:text-white
                      "
                    ></i>
                  </div>
                  <router-link
                    to="/dashboard/all-talents"
                    class="
                      text-lg
                      self-center
                      group-hover:text-primary
                      cursor-pointer
                    "
                  >
                    All Talents
                  </router-link>
                </li>
                <li class="flex group">
                  <div
                    class="bg-gray rounded-md group-hover:bg-primary p-2 mr-3"
                  >
                    <i
                      class="
                        uil uil-postcard
                        text-lg
                        icon-gray
                        group-hover:text-white
                      "
                    ></i>
                  </div>
                  <router-link
                    to="/dashboard/blog-post"
                    class="
                      text-lg
                      self-center
                      group-hover:text-primary
                      cursor-pointer
                    "
                  >
                    Posts
                  </router-link>
                </li>
              </ul>
              <div class="absolute bottom-10">
                <li
                  class="flex group text-red-600 text-xl cursor-pointer"
                  @click="logOut"
                >
                  <p class="">Log Out</p>
                  <i class="ml-2 uil uil-signout"></i>
                </li>
              </div>
            </ul>
          </div>
        </transition>
      </div>
    </div>
    <div
      class="
        lg:hidden
        flex
        items-center
        justify-center
        fixed
        right-5
        top-2
        rounded-full
        h-14
        w-14
        bg-white
        shadow-sm
      "
      @click.prevent="toggleMenu"
    >
      <i class="uil uil-bars cursor-pointer text-primary text-4xl"></i>
    </div>
  </div>
</template>
<script>
import { isLoggedIn } from "../util/user";
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  mounted() {
    const x = isLoggedIn();
    if (!x) {
      this.$router.push("/");
    }
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    logOut() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
  },
};
</script>
<style>
ul {
  list-style-type: none;
}
.nav-icon {
  /* back */
}
.icon-gray {
  color: #535151;
}

.sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
}
.mobile-menu-new {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 70%;
}
</style>