<template>
  <div class="">
    <div class="">
      <div class="md:flex md:justify-between mt-5">
        <h1 class="text-2xl font-semibold mb-4">Welcome Admin🙌</h1>
        <!-- Search and Profile -->
        <div class="md:flex gap-x-4 md:justify-end items-center md:mt-0">
          <div class="bg-gray-light text-gray-medDark flex rounded-xl lg:w-64">
            <i class="uil uil-search text-lg px-2 self-center"></i>
            <input
              type="search"
              class="bg-gray-light outline-none py-3"
              name=""
              v-model="searchInput"
              placeholder="Search"
              @keydown="search"
            />
          </div>
          <div class="w-10 ml-auto my-3 md:m-0">
            <img src="../assets//profile-image.png" alt="" class="" />
          </div>
        </div>
      </div>
      <div class="ml-auto text-right">
        <h1 class="text-2xl font-bold text-primary">{{ weekDate() }}</h1>
        <h1 class="text-xl font-bold text-secondary">{{ currentDate() }}</h1>
      </div>
    </div>
    <!-- Talent Pool Table -->
    <div class="xl:flex gap-5 my-5">
      <div class="xl:w-8/12 flex flex-col gap-y-5">
        <div class="bg-white flex flex-col p-4 justify-center rounded-lg">
          <h1 class="header6 py-4 text-primary">Talent Pool</h1>
          <div class="table-container">
            <table class="table-auto mov talent-table cursor-pointer para1">
              <thead>
                <tr class="text-left border-b-2 border-gray-100">
                  <th class="pr-3 h-12">S/N</th>
                  <th class="">Name</th>
                  <th class="">Date of Application</th>
                  <th class="">Stack</th>
                  <th class="">Actions</th>
                </tr>
              </thead>
              <tbody class="">
                <tr
                  class="h-12 mod-rel"
                  v-for="(user, index) in users"
                  :key="user.index"
                >
                  <td class="font-bold">{{ index + 1 }}</td>
                  <td class="font-bold">
                    {{ user.lastName }} {{ user.firstName }}
                  </td>
                  <td>
                    {{ user.createdAt.slice(0, 10) }}
                  </td>
                  <td>{{ user.primaryStack }}</td>
                  <td class="text-center" @click="talentModal(index)">
                    <i class="uil uil-ellipsis-h text-2xl"></i>
                  </td>
                  <div class="mod-rel" v-if="talentMiniModal">
                    <div
                      v-show="miniTalentModal == index"
                      class="
                        modal
                        w-40
                        rounded-md
                        shadow-lg
                        bg-white
                        py-3
                        mt-10
                      "
                    >
                      <ul class="">
                        <li class="py-2 pl-3">
                          <router-link :to="`/dashboard/user/${user._id}`"
                            >View User Details</router-link
                          >
                        </li>
                        <li
                          v-if="!user.isApproved"
                          class="py-2 pl-3"
                          @click="approveModal"
                        >
                          Approve User
                        </li>
                        <li
                          v-if="user.isApproved"
                          class="py-2 pl-3"
                          @click="disapproveModal"
                        >
                          Disapprove User
                        </li>
                      </ul>

                      <!-- Approved Modal -->
                      <div
                        v-if="approve"
                        class="
                          backdrop
                          flex flex-no-wrap
                          justify-center
                          items-center
                        "
                        @click.self="approveModal"
                      >
                        <div
                          class="
                            flex flex-col flex-no-wrap
                            bg-white
                            rounded-lg
                            modal-container
                          "
                        >
                          <div class="content text-center m-5">
                            <div class="flex flex-col justify-center my-5">
                              <div class="mx-auto">
                                <img
                                  src="../assets/Approved.png"
                                  alt=""
                                  class=""
                                />
                              </div>
                              <span class="flex py-2 justify-center text-lg">
                                <p class="font-medium pr-2">Approve</p>
                                <p class="font-semibold">
                                  {{ user.lastName }} {{ user.firstName }}
                                </p>
                              </span>
                            </div>
                            <div class="flex justify-center gap-x-4">
                              <button
                                class="
                                  bg-gray-200
                                  rounded-lg
                                  px-5
                                  py-4
                                  text-gray-medDark
                                  font-semibold
                                  hover:bg-error hover:text-white
                                  cursor-pointer
                                  outline-none
                                "
                                @click.prevent="approveModal"
                              >
                                Cancel
                              </button>
                              <button
                                class="
                                  bg-success
                                  rounded-md
                                  py-4
                                  text-white
                                  font-semibold
                                  px-5
                                  cursor-pointer
                                  outline-none
                                  flex
                                  justify-center
                                  self-center
                                "
                                @click="approveUser(user._id)"
                              >
                                Approve User &nbsp;
                                <loader
                                  :loading="approving"
                                  class="self-center"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Disapproved Modal -->
                      <div
                        v-if="disapprove"
                        class="
                          backdrop
                          flex flex-no-wrap
                          justify-center
                          items-center
                        "
                        @click.self="disapproveModal"
                      >
                        <div
                          class="
                            flex flex-col flex-no-wrap
                            bg-white
                            rounded-lg
                            modal-container
                          "
                        >
                          <div class="content text-center m-5">
                            <div class="flex flex-col justify-center my-5">
                              <div class="mx-auto">
                                <img
                                  src="../assets/disapprove.png"
                                  alt=""
                                  class=""
                                />
                              </div>
                              <span class="flex py-2 justify-center text-lg">
                                <p class="font-medium pr-2">Disapprove</p>
                                <p class="font-semibold">
                                  {{ user.lastName }} {{ user.firstName }}
                                </p>
                              </span>
                            </div>
                            <!-- Not included -->
                            <!-- <div class="text-left m-4">
                        <p class="font-semibold pb-2">
                          Reasons for disapproval:
                        </p>
                        <input
                          type="text"
                          class="
                            bg-gray-light
                            text-gray-dark
                            w-full
                            p-3
                            outline-none
                          "
                          placeholder="Type reason here..."
                          name=""
                          id=""
                        />
                      </div> -->
                            <div class="flex justify-center gap-x-4">
                              <button
                                class="
                                  bg-gray-200
                                  rounded-lg
                                  w-40
                                  py-4
                                  text-gray-medDark
                                  font-semibold
                                  cursor-pointer
                                  outline-none
                                "
                                @click.prevent="disapproveModal"
                              >
                                Cancel
                              </button>
                              <button
                                class="
                                  bg-error
                                  rounded-md
                                  py-4
                                  text-white
                                  font-semibold
                                  w-40
                                  cursor-pointer
                                  outline-none
                                "
                                @click="disApproveUser(user._id)"
                              >
                                Disapprove User
                                <loader
                                  :loading="disapproving"
                                  class="self-center"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Article Table -->
        <div
          class="
            bg-white
            mt-10
            mb-5
            flex flex-col
            para1
            justify-center
            p-3
            rounded-lg
          "
        >
          <h1 class="header6 py-4 text-primary">Recent Article</h1>
          <div class="table-container">
            <table class="table-auto talent-table cursor-pointer para1">
              <thead>
                <tr class="text-left border-b-2 border-gray-100">
                  <th class="md:w-1/12 w-2/12">No.</th>
                  <th class="md:w-4/12">Blog Title</th>
                  <th class="md:w-2/12 w-3/12">Post Date</th>
                  <th class="md:w-3/12 hidden md:block">Category</th>
                  <th class="md:w-1/12 w-2/12">Views</th>
                  <th class="md:w-1/12">Actions</th>
                </tr>
              </thead>
              <tbody class="mod-rel">
                <tr
                  class="h-16"
                  v-for="(post, index) in posts"
                  :key="post.index"
                >
                  <td class="">{{ index + 1 }}</td>
                  <td>{{ post.title }}</td>
                  <td>{{ post.date }}</td>
                  <td class="hidden lg:block py-2">
                    <button
                      class="bg-secondary text-white font-bold rounded-md p-2"
                    >
                      {{ post.category }}
                    </button>
                  </td>
                  <td>1k</td>
                  <td class="text-center" @click="postModal(index)">
                    <i class="uil uil-ellipsis-h text-2xl"></i>
                  </td>
                  <div class="" v-if="blogmodal">
                    <div
                      v-show="miniBlogModal == index"
                      class="
                        modal
                        w-40
                        rounded-md
                        shadow-lg
                        bg-white
                        py-3
                        mt-10
                      "
                    >
                      <ul class="">
                        <li class="py-2 pl-3">Edit Post</li>
                        <li class="py-2 pl-3">Delete Post</li>
                      </ul>
                    </div>
                  </div>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Number Details -->
      <div class="xl:w-4/12 flex my-5 flex-col gap-5">
        <div class="grid grid-cols-2 gap-5">
          <div class="bg-white rounded-lg p-4">
            <div class="flex justify-center text-success">
              <div class="bg-gray rounded-md p-1 mr-3">
                <i
                  class="uil uil-user-check text-3xl font-bold text-success"
                ></i>
              </div>
              <p class="text-3xl font-bold self-center">
                {{ approvedUsers }}
              </p>
            </div>
            <p class="text-gray-medium font-medium text-center">
              Approved Talent
            </p>
          </div>
          <div class="bg-white rounded-lg p-4">
            <div class="flex justify-center text-info">
              <div class="bg-gray rounded-md p-1 mr-3">
                <i class="uil uil-user text-3xl font-bold text-info"></i>
              </div>
              <p class="text-3xl font-bold self-center">{{ countUsers }}</p>
            </div>
            <p class="text-gray-medium font-medium text-center">
              Registered Talent
            </p>
          </div>
          <div class="bg-white rounded-lg p-4">
            <div class="flex justify-center text-primary">
              <div class="bg-gray rounded-md p-1 mr-3">
                <i
                  class="uil uil-file-blank text-3xl font-bold text-primary"
                ></i>
              </div>
              <p class="text-3xl font-bold self-center">302</p>
            </div>
            <p class="text-gray-medium font-medium text-center">Posts</p>
          </div>
          <div class="bg-white rounded-lg p-4">
            <div class="flex justify-center text-secondary">
              <div class="bg-gray rounded-md p-1 mr-3">
                <i
                  class="uil uil-analytics text-3xl font-bold text-secondary"
                ></i>
              </div>
              <p class="text-3xl font-bold self-center">5k</p>
            </div>
            <p class="text-gray-medium font-medium text-center">Visitors</p>
          </div>
        </div>
        <!-- Companies list -->
        <div class="bg-white p-3 rounded-lg mt-10">
          <h1 class="header6 py-4 text-primary">Companies</h1>
          <div class="table-container">
            <table class="table-auto talent-table cursor-pointer">
              <thead>
                <tr class="text-left">
                  <th class="w-1/12">S/N</th>
                  <th class="w-3/12">Name</th>
                  <th class="w-3/12">Website</th>
                  <th class="w-3/12">Users</th>
                </tr>
              </thead>
              <tbody class="para1">
                <tr>
                  <td class="pt-4">1.</td>
                  <td class="">Chuuse</td>
                  <td><a href="" class="text-primary">www.chuuse.org</a></td>
                  <td>10 Users</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
ul {
  list-style-type: none;
}
.nav-icon {
  /* back */
}
.icon-gray {
  color: #535151;
}
.modal ul li:hover {
  background-color: #24beca;
  color: #ffffff;
}
.mod-rel {
  position: relative;
}
.modal {
  position: absolute;
  right: 10%;
  top: 30px;
  z-index: 99990;
}
.talent-table {
  border-collapse: collapse;
  margin-top: 20px;
  width: 100%;
  border-radius: 6px;
}
.mov {
  z-index: 0;
}
.backdropMod {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100vh;
  z-index: -9;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100vh;
  z-index: 99999;
}

.modal-container {
  min-width: 400px;
  max-width: 400px;
  overflow: hidden;
  min-height: 300px;
}

@media screen and (max-width: 540px) {
  .talent-table {
    width: 200%;
  }
  .table-container {
    max-width: 100%;
    overflow-x: auto;
  }
  .modal-container {
    min-width: 90%;
    max-width: 90%;
    overflow: hidden;
    min-height: 300px;
  }
}
</style>
<script>
export default {
  data() {
    return {
      open: false,
      disapprove: false,
      miniTalentModal: false,
      talentMiniModal: false,
      approve: false,
      blogmodal: false,
      modal: false,
      modalData: false,
      minimodal: false,
      miniBlogModal: false,
      searchInput: "",
      day: "",
      posts: [
        {
          title: "Tips & Recommendation on Design Project",
          date: "0ct 30, 2021",
          category: "Web development",
          Views: "1K",
        },
      ],
    };
  },
  async mounted() {
    await this.$store.dispatch("getAllUsers");
    await this.$store.dispatch("getApprovedUser");
  },
  computed: {
    users() {
      const limUsers = this.$store.getters.getAllUsers;
      limUsers.slice(0, 6);
      return limUsers;
    },
    approvedUsers() {
      return this.$store.getters.getApprovedUsers.length;
    },
    countUsers() {
      return this.$store.getters.getAllUsers.length;
    },
    approving() {
      return this.$store.getters.approvingUser;
    },
    disapproving() {
      return this.$store.getters.disapprovingUser;
    },
  },
  methods: {
    weekDate() {
      const d = new Date();
      const weekDay = new Array(7);
      weekDay[0] = "Sunday";
      weekDay[1] = "Monday";
      weekDay[2] = "Tuesday";
      weekDay[3] = "Wednesday";
      weekDay[4] = "Thursday";
      weekDay[5] = "Friday";
      weekDay[6] = "Saturday";
      return weekDay[d.getDay()];
    },
    currentDate() {
      const current = new Date();
      const months = new Array(12);
      months[1] = "January";
      months[2] = "February";
      months[3] = "March";
      months[4] = "April";
      months[5] = "May";
      months[6] = "June";
      months[7] = "July";
      months[8] = "August";
      months[9] = "September";
      months[10] = "October";
      months[11] = "November";
      months[12] = "December";
      const date = `${current.getDate()} ${
        months[current.getMonth() + 1]
      } ${current.getFullYear()}`;
      return date;
    },
    showMinimodal(index) {
      this.minimodal = index;
      this.modal = !this.modal;
    },
    talentModal(index) {
      this.miniTalentModal = index;
      this.talentMiniModal = !this.talentMiniModal;
    },
    approveModal() {
      this.approve = !this.approve;
    },
    disapproveModal() {
      this.disapprove = !this.disapprove;
    },
    postModal(index) {
      this.miniBlogModal = index;
      this.blogmodal = !this.blogmodal;
    },
    search() {
      this.$store.dispatch("getSearchedUser", {
        search: this.searchInput,
        page: 1,
      });
    },
  },
};
</script>